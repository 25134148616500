import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Typography } from "@mui/material";
import FormInput from "../../components/formComponents/FormInput";
import Button from "../../components/Button";
import { STRING_VALIDATION } from "./alertsUtils";
import useUpdateSlackConf from "./useUpdateSlackConf";
import SlackColorIcon from "../../Icons/SlackColorIcon";
import Tooltip from "../../components/Tooltip";
import useValidateSlackToken from "./useValidate";
import WarningIcon from "../../Icons/WarningIcon";
import { MAIN_YELLOW } from "../../colors";
import { components } from "../../api/schema";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";


interface Props {
  token: string | undefined;
  showTokenIsInvalidError: boolean;
  isMultiCluster: boolean;
  showValidation: boolean;
  onReset?: () => void;
  onSlackTokenChange?: (valid: boolean) => void;
  tokenIsValid?: boolean;
  tokenValidationError: TokenValidationError | undefined;
}

const pretifyTokenError = (errorMsg: string | undefined) => {
  if (errorMsg === undefined) {
    return "Invalid Slack Token";
  }
  if (errorMsg.startsWith("missing required scopes:")) {
    return (
      <div>
        <b>Missing required permissions(scopes):</b>
        {errorMsg
          .split("scopes:")[1]
          .trim()
          .split(",")
          .map((scope) => scope.trim())
          .map((scope) => (
            <li>{scope}</li>
          ))}
      </div>
    );
  }
  if (errorMsg.startsWith("failed to call auth.test")) {
    return (
      <div>
        <b>Bad Token:</b> this token is not recognized by slack API
      </div>
    );
  }
  return <div>{errorMsg}</div>;
};

export type TokenValidationError = components["schemas"]["SlackTokenVerificationError"];

const TokenForm = ({
  token,
  showTokenIsInvalidError,
  isMultiCluster,
  showValidation,
  onReset,
  onSlackTokenChange,
  tokenIsValid,
  tokenValidationError,
}: Props) => {
  const [submitWasClicked, setSubmitWasClicked] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const updateSlackConf = useUpdateSlackConf();
  const validateSlackToken = useValidateSlackToken({
    onError: () => {
      onSlackTokenChange && onSlackTokenChange(false);
    },
    onSuccess: () => {
      onSlackTokenChange && onSlackTokenChange(true);
    },
  });

  return (
    <Formik
      initialValues={{
        token: token,
      }}
      validationSchema={Yup.object({
        token: STRING_VALIDATION,
      })}
      onSubmit={(values) => {
        setSubmitWasClicked(true);
        const input = {
          multiCluster: isMultiCluster,
          token: values.token,
        };
        updateSlackConf.mutate(input);
        validateSlackToken.mutate({ token: values.token || "" });
        setResetClicked(false);
      }}
    >
      {(formik) => {
        useEffect(() => {
          formik.validateForm();
        }, []);

        return (
          <Form className="pt-8">
            <div className="flex relative items-end gap-5 w-[370px]">
              <div className="flex items-center grow relative">
                <FormInput
                  label={
                    <div className="flex items-center gap-1">
                      <SlackColorIcon width={14} height={14} />
                      {tokenIsValid === false && (
                        <Tooltip
                          title={<div className="w-fit">{pretifyTokenError(tokenValidationError?.message)}</div>}
                          maxWidth={500}
                          className={"w-fit"}
                        >
                          <WarningIcon height={14} width={14} fill={MAIN_YELLOW} />
                        </Tooltip>
                      )}
                      Slack Token
                    </div>
                  }
                  name="token"
                  placeholder="Token"
                  required
                  className="w-full"
                  type={"password"}
                  sx={{
                    paddingRight: "34px",
                  }}
                  showHidePasswordToggle={true}
                />
              </div>
              {!!formik.values.token && formik.values.token === token ? (
                <Tooltip
                  title={
                    <>
                      Click to reset the token. <br />
                      <b>Warning:</b> This will remove the token and default slack channel and will require you to
                      re-enter it.
                    </>
                  }
                >
                  <Button
                    type="button"
                    label="Reset Token"
                    variant="small"
                    className="h-[34px] py-0 mt-[2px]"
                    disabled={resetClicked || isReadyOnlyFrontEnd}
                    onClick={() => {
                      const input = {
                        multiCluster: isMultiCluster,
                        token: "",
                        defaultChannel: { id: "", name: "" },
                      };
                      updateSlackConf.mutate(input);
                      formik.resetForm({ values: { token: "" } });
                      onReset && onReset();
                      setResetClicked(true);
                    }}
                  />
                </Tooltip>
              ) : (
                <Button
                  type="submit"
                  label="Save Token"
                  disabled={!formik.isValid || formik.values.token === token || isReadyOnlyFrontEnd}
                  variant="small"
                  className="h-[34px] py-0 mt-[2px]"
                  onClick={() => {
                    setResetClicked(false);
                  }}
                />
              )}
            </div>
            {!resetClicked && showValidation && !showTokenIsInvalidError && submitWasClicked && (
              <Typography variant="caption" className="text-text-disable py-2 flex items-start">
                Validating token
                <div className="dot-elastic mt-2 ml-4" />
              </Typography>
            )}
            {!resetClicked && !showValidation && showTokenIsInvalidError && submitWasClicked && (
              <Typography variant="body2" className="text-main-red py-2">
                {pretifyTokenError(tokenValidationError?.message)}
              </Typography>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default TokenForm;
