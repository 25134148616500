import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import {
  ASC,
  getClusterNameRow,
  getCostRow,
  getMemoryRow,
  getNumberRow,
  getPercentageRow,
  getWithSubHeader,
  renderGpuIdleTimeRatioHeader,
  SubHeaderType,
} from "../../../components/WorkloadStatusByNamespace/utils";
import prettyBytes from "pretty-bytes";

export enum TableType {
  Workloads = "Workloads",
  Aggregation = "Aggregations",
}

export enum Field {
  id = "id",
  workloadName = "workloadName",
  clusterName = "clusterName",
  totalCost = "totalCost",
  savingsAvailable = "savingsAvailable",
  activeSavings = "activeSavings",
  spot = "spot",
  onDemand = "onDemand",
  cpu = "ownerCpuRequest",
  gpu = "ownerGpuRequest",
  memory = "ownerMemoryRequest",
  replicas = "replicas",
  amountOfWorkloads = "amountOfWorkloads",
  crossAZCost = "crossAZCost",
  intraAZCost = "intraAZCost",
  totalDataTransfer = "totalDataTransfer",
  intraAZDataTransfer = "intraAZDataTransfer",
  crossAZDataTransfer = "crossAZDataTransfer",
  avgGpuUtilization = "avgGpuUtilization",
  avgGpuMemory = "avgGpuMemory",
  avgGpuMemoryUtilization = "avgGpuMemoryUtilization",
  gpuIdleTimeRatio = "gpuIdleTimeRatio",
}

export enum ColumnNames {
  id = "Name",
  workloadName = "Workload",
  clusterName = "Cluster",
  totalCost = "Total Cost",
  savingsAvailable = "Savings Available",
  activeSavings = "Active Savings",
  spot = "Spot %",
  onDemand = "On-demand %",
  ownerCpuRequest = "CPU Request",
  ownerGpuRequest = "GPU Request",
  ownerMemoryRequest = "Memory Request",
  replicas = "Replicas",
  amountOfWorkloads = "Amount of Workloads",
  crossAZCost = "Cross-AZ Cost",
  totalDataTransfer = "Total Data Transfer",
  intraAZDataTransfer = "Intra-AZ Data Transfer",
  crossAZDataTransfer = "Cross-AZ Data Transfer",
  avgGpuUtilization = "GPU Usage",
  avgGpuMemory = "GPU Memory",
  avgGpuMemoryUtilization = "GPU Memory Usage",
  gpuIdleTimeRatio = "GPU Idle Time",
}

type SortData = {
  id: string;
  clusterName: string;
  namespace: string;
  onDemand: number;
  ownerCpuRequest: number;
  ownerGpuRequest: number;
  ownerMemoryRequest: number;
  replicas: number;
  savingsAvailable: number;
  activeSavings: number;
  spot: number;
  totalCost: number;
  workloadName: string;
  workloadType: string;
  amountOfWorkloads: number;
  avgGpuUtilization: number;
  avgGpuMemory: number;
  avgGpuMemoryUtilization: number;
  gpuIdleTimeRatio: number;
};
interface getSortedRowsParas<T> {
  sortField: string | null | undefined;
  sortDirection: string | null | undefined;
  sortedData: T[];
}

const getDataField = <T extends SortData>(field: string, row: T) => {
  switch (field) {
    case Field.clusterName:
      return row.clusterName;
    case Field.totalCost:
      return row.totalCost;
    case Field.savingsAvailable:
      return row.savingsAvailable;
    case Field.activeSavings:
      return row.activeSavings;
    case Field.spot:
      return row.spot;
    case Field.onDemand:
      return row.onDemand;
    case Field.cpu:
      return row.ownerCpuRequest;
    case Field.gpu:
      return row.ownerGpuRequest;
    case Field.memory:
      return row.ownerMemoryRequest;
    case Field.replicas:
      return row.replicas;
    case Field.amountOfWorkloads:
      return row.amountOfWorkloads;
    case Field.avgGpuUtilization:
      return row.avgGpuUtilization;
    case Field.avgGpuMemory:
      return row.avgGpuMemory;
    case Field.avgGpuMemoryUtilization:
      return row.avgGpuMemoryUtilization;
    case Field.gpuIdleTimeRatio:
      return row.gpuIdleTimeRatio;
    default:
      return row.clusterName;
  }
};

export const getSortedColumn = <T extends SortData>({
  sortField,
  sortDirection,
  sortedData,
}: getSortedRowsParas<T>) => {
  if (!sortField) {
    return sortedData;
  }

  return sortedData.sort((a, b) => {
    const fieldA = getDataField(sortField, a);
    const fieldB = getDataField(sortField, b);

    const diff =
      (() => {
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          return fieldA.localeCompare(fieldB);
        } else if (typeof fieldA === "number" && typeof fieldB === "number") {
          return fieldA - fieldB;
        }
      })() ?? 0;

    if (sortDirection === ASC) {
      return diff;
    } else {
      return -diff;
    }
  });
};

export const getSharedColumns = (
  selectedColumns: (string | undefined)[],
  replicasHeaderName = ColumnNames[Field.replicas] as string
): GridColDef[] => [
  {
    field: Field.clusterName,
    headerName: ColumnNames[Field.clusterName],
    hide: !selectedColumns.includes(Field.clusterName),
    flex: 1,
    minWidth: 200,
    type: "string",
    align: "left",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) =>
      getClusterNameRow({
        clusterName: params.row.clusterName,
      }),
  },
  {
    field: Field.totalCost,
    headerName: ColumnNames[Field.totalCost],
    hide: !selectedColumns.includes(Field.totalCost),
    flex: 1,
    minWidth: 120,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.totalCost,
      }),
  },
  {
    field: Field.savingsAvailable,
    headerName: ColumnNames[Field.savingsAvailable],
    hide: !selectedColumns.includes(Field.savingsAvailable),
    flex: 1,
    minWidth: 171,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.savingsAvailable,
        colorBySavings: true,
      }),
  },
  {
    field: Field.activeSavings,
    headerName: ColumnNames[Field.activeSavings],
    hide: !selectedColumns.includes(Field.activeSavings),
    flex: 1,
    minWidth: 171,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) =>
      getCostRow({
        cost: params.row.activeSavings,
        colorBySavings: true,
      }),
  },
  {
    field: Field.spot,
    headerName: ColumnNames[Field.spot],
    hide: !selectedColumns.includes(Field.spot),
    flex: 1,
    minWidth: 120,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      const total = params.row.spot + params.row.onDemand;
      let percentage = Math.round((params.row.spot / total) * 100);

      if (isNaN(percentage) || percentage < 0 || percentage > 100) percentage = 0;

      return getPercentageRow({ percentage });
    },
  },
  {
    field: Field.onDemand,
    headerName: ColumnNames[Field.onDemand],
    hide: !selectedColumns.includes(Field.onDemand),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      const total = params.row.spot + params.row.onDemand;
      let percentage = Math.round((params.row.onDemand / total) * 100);

      if (isNaN(percentage) || percentage < 0 || percentage > 100) percentage = 0;

      return getPercentageRow({ percentage });
    },
  },
  {
    field: Field.cpu,
    headerName: ColumnNames[Field.cpu],
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Average, params.colDef.headerName),
    hide: !selectedColumns.includes(Field.cpu),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => getNumberRow({ value: params.row.ownerCpuRequest }),
  },
  {
    field: Field.gpu,
    headerName: ColumnNames[Field.gpu],
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Average, params.colDef.headerName),
    hide: !selectedColumns.includes(Field.gpu),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => getNumberRow({ value: params.row.ownerGpuRequest }),
  },
  {
    field: Field.memory,
    headerName: ColumnNames[Field.memory],
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Average, params.colDef.headerName),
    hide: !selectedColumns.includes(Field.memory),
    flex: 1,
    minWidth: 162,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => getMemoryRow({ memory: params.row.ownerMemoryRequest }),
  },
  {
    field: Field.replicas,
    headerName: replicasHeaderName,
    hide: !selectedColumns.includes(Field.replicas),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => Math.round(params.row?.replicas ?? 0),
  },
  {
    field: Field.amountOfWorkloads,
    headerName: ColumnNames[Field.amountOfWorkloads],
    hide: !selectedColumns.includes(Field.amountOfWorkloads),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => Math.round(params.row?.amountOfWorkloads ?? 0),
  },
  {
    field: Field.avgGpuUtilization,
    headerName: ColumnNames[Field.avgGpuUtilization],
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Average, params.colDef.headerName),
    hide: !selectedColumns.includes(Field.avgGpuUtilization),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      const percentage = Math.round(params.row?.avgGpuUtilization * 100 ?? 0);
      return `${percentage.toFixed(1)}%`;
    },
  },
  {
    field: Field.avgGpuMemory,
    headerName: ColumnNames[Field.avgGpuMemory],
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Average, params.colDef.headerName),
    hide: !selectedColumns.includes(Field.avgGpuMemory),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      return prettyBytes(params.row?.avgGpuMemory * 1024 * 1024);
    },
  },
  {
    field: Field.avgGpuMemoryUtilization,
    headerName: ColumnNames[Field.avgGpuMemoryUtilization],
    renderHeader: (params) => getWithSubHeader(SubHeaderType.Average, params.colDef.headerName),
    hide: !selectedColumns.includes(Field.avgGpuMemoryUtilization),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      const percentage = Math.round(params.row?.avgGpuMemoryUtilization * 100 ?? 0);
      return `${percentage.toFixed(1)}%`;
    },
  },
  {
    field: Field.gpuIdleTimeRatio,
    headerName: ColumnNames[Field.gpuIdleTimeRatio],
    hide: !selectedColumns.includes(Field.gpuIdleTimeRatio),
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      const percentage = Math.round(params.row?.gpuIdleTimeRatio * 100 ?? 0);
      return `${percentage.toFixed(1)}%`;
    },
    renderHeader: renderGpuIdleTimeRatioHeader,
  },
];
