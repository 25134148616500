import clsx from "clsx";

interface Props {
  size?: number;
  className?: string;
}

const Ping = ({ size = 10, className }: Props) => (
  <div
    className={clsx(className, "absolute top-[-10px] rounded-full flex items-center justify-center bg-white")}
    style={{
      width: size,
      height: size,
    }}
  >
    <span
      className="custom-animate-ping duration-1050 absolute inline-flex rounded-full bg-main-blue opacity-50"
      style={{
        width: size * 2,
        height: size * 2,
      }}
    />
    <span
      className="absolute inline-flex rounded-full bg-main-blue opacity-75"
      style={{
        width: size,
        height: size,
      }}
    />
  </div>
);

export default Ping;
